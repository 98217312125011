<!--门票销售数据-->
<template>
    <div class="night-container">
        <div class="tip-box flex ac jb">
            <div class="flex ac">
                <div class="every-tip" @click="handleJump(0)">
                    日游数据
                </div>
                <div class="every-tip-actived" @click="handleJump(1)">
                    夜游数据
                </div>
            </div>
            <div class="all-db-box flex ac jb">
                <div class="all-db flex ac">
                    <div class="every-db" v-for="(item,index) in dbListAll" :key="index"
                         @click="handleEdit(2,item,index)">
                        <div class="pp1">{{item.title}}</div>
                        <!--                        <div class="pp2">{{item.num}}</div>-->
                    </div>
                </div>
                <div class="db-box" @click="showDb()">添加对比</div>
            </div>

        </div>
        <div class="all-moudle flex ac jb fw">
            <div class="moudle-box part1">
                <div class="search-box flex ac jb">
                    <img class="img1" src="../assets/daymanage/10-2-title-jiedai@2x.png" alt="11">
                    <!--                    <div class="db-box" @click="showDb(1)">添加对比</div>-->
                </div>
                <!--                <div class="all-db flex ac">-->
                <!--                    <div class="every-db" v-for="(item,index) in dbList1" :key="index" style="width: 14%"-->
                <!--                         @click="handleEdit(1,item,index)">-->
                <!--                        <div class="pp1">{{item.title}}</div>-->
                <!--                        <div class="pp2">{{item.num}}</div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="mb45">
                    <Echart :options="option1" id="chart1" height="300px" width="820px"></Echart>
                </div>
            </div>
            <div class="moudle-box part2">
                <div class="search-box flex ac jb">
                    <img class="img1" src="../assets/daymanage/10-2-title-jingying@2x.png" alt="11">
                    <!--                    <div class="db-box" @click="showDb(2)">添加对比</div>-->
                </div>
                <!--                <div class="all-db flex ac">-->
                <!--                    <div class="every-db" v-for="(item,index) in dbList2" :key="index" style="width: 14%"-->
                <!--                         @click="handleEdit(2,item,index)">-->
                <!--                        <div class="pp1">{{item.title}}</div>-->
                <!--                        <div class="pp2">{{item.num}}</div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="mb45">
                    <Echart :options="option2" id="chart1" height="300px" width="820px"></Echart>
                </div>
            </div>
            <div class="moudle-box part3">
                <div class="search-box flex ac jb">
                    <img class="img1" src="../assets/daymanage/10-2-title-menpiao@2x.png" alt="11">
                    <!--                    <div class="all-date-box" style="margin-left: 10px">-->
                    <!--                        <el-date-picker-->
                    <!--                                v-model="date3"-->
                    <!--                                type="daterange"-->
                    <!--                                :clearable="false"-->
                    <!--                                range-separator="-"-->
                    <!--                                start-placeholder="开始日期"-->
                    <!--                                end-placeholder="结束日期"-->
                    <!--                                :picker-options="pickerOptions"-->
                    <!--                                value-format="yyyy-MM-dd"-->
                    <!--                                placement="bottom-start"-->
                    <!--                                size="small"-->
                    <!--                                style="width: 100%"-->
                    <!--                                @change="handleDate3"-->
                    <!--                        ></el-date-picker>-->
                    <!--                    </div>-->
                </div>
                <div class="info-box flex ac jb">
                    <div class="left-box">
                        <Echart :options="option3" id="chart2" height="268px" width="268px"></Echart>
                    </div>
                    <div class="right-box">
                        <div>
                            <div class="table-head flex ac">
                                <div class="head1">景区</div>
                                <div class="head2">门票</div>
                                <div class="head4">项目</div>

                            </div>
                            <div class="imp-info">
                                <div class="table-info flex ac" v-for="(item,index) in zbList" :key="index">
                                    <div class="head1">{{item.scenic_spot_name}}</div>
                                    <div class="head2">¥{{item.ticket_format}}({{item.ticket_percent}}%)</div>
                                    <div class="head4">¥{{item.project_format}}({{item.project_percent}}%)</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="moudle-box part4">
                <div class="search-box renjun-img">
                    <img class="img1" src="../assets/daymanage/10-2-title-renjun@2x.png" alt="11">
                    <div class="wenHao">
                        <el-popover
                                placement="top-start"
                                title=""
                                width="240"
                                trigger="hover"
                        >
                            <div class="gongshi" style="white-space: nowrap;font-size: 12px">人均消费=总收入(门票和项目)/总人数</div>
                            <div slot="reference"><img src="../assets/questionIcon.png" alt="Q"
                                                       style="width: 100%;height: 100%"></div>
                        </el-popover>
                    </div>

                    <!--                    <div class="db-box" @click="showDb(4)">添加对比</div>-->
                </div>
                <!--                <div class="all-db flex ac">-->
                <!--                    <div class="every-db" v-for="(item,index) in dbList4" :key="index" style="width: 14%"-->
                <!--                         @click="handleEdit(4,item,index)">-->
                <!--                        <div class="pp1">{{item.title}}</div>-->
                <!--                        <div class="pp2">{{item.num}}</div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="mb45">
                    <Echart :options="option4" id="chart1" height="300px" width="820px"></Echart>
                </div>
            </div>
        </div>
        <el-dialog
                title="添加对比"
                :visible.sync="dialogVisible"
                width="30%"
                top="30vh"
                :modal-append-to-body="false"
        >
            <div>
                <div class="flex ac">
                    <div style="margin-right: 5px;white-space: nowrap">选择年份:</div>
                    <el-date-picker
                            v-model="date2"
                            type="daterange"
                            :clearable="false"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd"
                            placement="bottom-start"
                            size="small"
                            style="width: 100%"
                            @change="handleDate2"
                            clearable
                    ></el-date-picker>
                </div>
                <div class="kjxz">快捷选择</div>
                <div class="chooseYearBox flex ac">
                    <div style="margin-right: 5px">选择年份:</div>
                    <el-date-picker
                            v-model="yearValue2"
                            type="year"
                            placeholder="选择年"
                            @change="chooseYaer2"
                            format="yyyy"
                            value-format="yyyy"
                    >
                    </el-date-picker>
                </div>
                <div class="month-box flex fw" style="margin-top: 5px">
                    <div style="margin-right: 5px">选择月份:</div>
                    <div style="width: 80%">
                        <el-checkbox-group v-model="monthValue2" size="mini" :disabled="checkDisable3"
                                           :max="1"
                                           @change="handleMonth2">
                            <el-checkbox label="01" border>一月</el-checkbox>
                            <el-checkbox label="02" border>二月</el-checkbox>
                            <el-checkbox label="03" border>三月</el-checkbox>
                            <el-checkbox label="04" border>四月</el-checkbox>
                            <el-checkbox label="05" border>五月</el-checkbox>
                            <el-checkbox label="06" border>六月</el-checkbox>
                            <el-checkbox label="07" border>七月</el-checkbox>
                            <el-checkbox label="08" border>八月</el-checkbox>
                            <el-checkbox label="09" border>九月</el-checkbox>
                            <el-checkbox label="10" border>十月</el-checkbox>
                            <el-checkbox label="11" border>十一月</el-checkbox>
                            <el-checkbox label="12" border>十二月</el-checkbox>
                        </el-checkbox-group>
                    </div>

                </div>
                <div class="month-box flex fw">
                    <div style="margin-right: 5px">选择节假日:</div>
                    <div style="width: 80%">
                        <el-checkbox-group v-model="holidayValue2" size="mini" :disabled="checkDisable4"
                                           :max="1"
                                           @change="handleHoliday2">
                            <div class="flex ac fw">
                                <div v-for="item in holidayList" :key="item.id"
                                     style="margin-right: 5px">
                                    <el-checkbox :label="item.name" border/>
                                </div>
                            </div>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="cannelDialog">取 消</el-button>
    <el-button type="warning" @click="moveDialog" v-if="editState">移除对比</el-button>
    <el-button type="primary" @click="sumbitDialog">确认对比</el-button>
  </span>
        </el-dialog>
    </div>
</template>
<script>
    import {
        tjHolidayList,
        tjReportNightTourist,
        tjReportNightIncome,
        tjReportNightIncomeType,
        tjReportNightIncomePer
    } from "../api";
    import {formatTime} from "../utils";
    import Echart from "../common/echart";


    export default {
        name: '',
        components: {
            Echart,
        },
        data() {
            return {
                yearValue2: new Date().getFullYear().toString(),
                monthValue2: [],
                holidayValue2: [],

                checkDisable3: false,
                checkDisable4: false,
                editState: false,
                choosePart: '',
                chooseIndex: '',
                yesterdayFormatted: '',
                dialogVisible: false,
                whichOne: 0,

                xDataList1: [],
                xDataList2: [],
                xDataList3: [],
                xDataList4: [],
                date3: '',


                option1: {},
                dbListAll: [],
                dbList1: [],
                plList1: [],
                series1: [],

                option2: {},
                dbList2: [],
                plList2: [],
                series2: [],

                option3: {},
                dbList3: [],
                plList3: [],
                series3: [],

                option4: {},
                dbList4: [],
                plList4: [],
                series4: [],

                tsscData: [],
                zbList: [],


                colorList: ["#5B8FF9", "#207BF0", "#37D2FF", "#4EEB9B", "#00F4E0", "#604EEB",
                    '#DF4EEB', '#EB4EBD', '#FF99C3', '#FF6F6F', '#EBA94E', '#B6D85A', '#AA4EEB'],
                month2: formatTime(new Date(), "yyyy-MM"),
                pickerOptions: {
                    // 限制日期范围为一个月
                    onPick: ({maxDate, minDate}) => {
                        this.isRestart = false;
                        this.pickerMinDate = minDate.getTime();
                        if (maxDate) {
                            this.pickerMinDate = "";
                        }
                    },

                    disabledDate: (time) => {
                        if (this.pickerMinDate !== "") {
                            const one = 12 * 31 * 24 * 3600 * 1000;
                            const minTime = this.pickerMinDate - one;
                            const maxTime = this.pickerMinDate + one;
                            return time.getTime() < minTime || time.getTime() > maxTime;
                        }
                    },
                },
                date2: [],
                holidayList: [],
                monthValue2: [],
                holidayValue2: [],

            }
        },
        created() {
            this.getBeforeDate()
        },
        methods: {
            getBeforeDate() {
                var today = new Date();
                var yesterday = new Date(today);
                yesterday.setDate(today.getDate() - 1);
                this.yesterdayFormatted = yesterday.toISOString().slice(0, 10);
                this.getDate1(this.yesterdayFormatted, this.yesterdayFormatted)
                this.getDate2(this.yesterdayFormatted, this.yesterdayFormatted)
                this.getDate3(this.yesterdayFormatted, this.yesterdayFormatted)
                this.getDate4(this.yesterdayFormatted, this.yesterdayFormatted)
            },
            handleJump(val) {
                switch (val) {
                    case 0:
                        this.$router.push("/management");
                        break;
                    case 1:
                        this.$router.push("/nightTour");
                        break;
                }
            },
            showDb() {
                this.editState = false
                this.chooseIndex = ''
                if (this.dbListAll.length >= 5) {
                    this.$message.error('最多添加五组对比数据')
                    return false
                }
                this.getTjHolidayList(new Date().getFullYear());
                // this.whichOne = val
                this.holidayValue2 = []
                this.monthValue2 = []
                this.dialogVisible = true
                this.checkDisable3 = false
                this.checkDisable4 = false
            },
            sumbitDialog() {
                if (!this.date2 || this.date2.length <= 0) {
                    this.$message.error("请选择对比日期");
                    return false;
                }
                var name1 = ''
                name1 = this.holidayValue2.length > 0 ? this.holidayValue2[0] : this.monthValue2.length > 0 ? this.monthValue2[0] : ''
                this.getDate1(this.date2[0], this.date2[1], name1)
                this.getDate2(this.date2[0], this.date2[1], name1)
                console.log('121212', this.dbListAll)
                setTimeout(() => {
                    this.getDate3(this.dbListAll[0].date[0], this.dbListAll[0].date[1], name1)
                }, 500)
                // this.getDate3(this.date2[0], this.date2[1], name1)
                this.getDate4(this.date2[0], this.date2[1], name1)

                this.dialogVisible = false
            },
            handleEdit(val, item, index) {
                this.choosePart = val
                this.whichOne = val
                this.chooseIndex = index
                this.editState = true
                this.dialogVisible = true

                this.date2 = item.date
                this.holidayValue2 = []
                this.monthValue2 = []
                if (item.holiday) {
                    this.checkDisable4 = false
                    this.checkDisable3 = true
                    this.holidayValue2 = item.holiday
                }
                if (item.month) {
                    this.checkDisable3 = false
                    this.checkDisable4 = true
                    this.monthValue2 = item.month
                }

            },
            // 移除对比
            moveDialog() {
                this.plList1.splice(this.chooseIndex, 1);
                this.dbListAll.splice(this.chooseIndex, 1);
                this.series1.splice(this.chooseIndex, 1);

                this.plList2.splice(this.chooseIndex, 1);
                this.series2.splice(this.chooseIndex, 1);

                this.plList3.splice(this.chooseIndex, 1);
                this.series3.splice(this.chooseIndex, 1);

                this.plList4.splice(this.chooseIndex, 1);
                this.series4.splice(this.chooseIndex, 1);
                this.dialogVisible = false

            },
            cannelDialog() {
                this.dialogVisible = false
            },
            // 获取节假日列表
            getTjHolidayList(year) {
                tjHolidayList({year: year}).then((res) => {
                    this.holidayList = res.data;
                });
            },
            handleDate2() {
                if (this.date2 && this.date2.length > 0) {
                    this.monthValue2 = [];
                    this.holidayValue2 = [];
                }
                this.checkDisable3 = false;
                this.checkDisable4 = false;
            },
            // handleDate3(val) {
            //     if(val){
            //         this.getDate3(val[0],val[1])
            //     }else {
            //         this.getDate3(this.yesterdayFormatted, this.yesterdayFormatted)
            //     }
            // },
            chooseYaer2(val) {
                this.monthValue2 = [];
                this.holidayValue2 = [];
                this.checkDisable3 = false;
                this.checkDisable4 = false;
                this.getTjHolidayList(val);
                if (this.monthValue2.length > 0) {
                    var lasyDay = "";
                    lasyDay = this.getLastDay(val, this.monthValue2.toString());
                    this.date2 = [val + "-" + this.monthValue2.toString() + "-01", val + "-" + this.monthValue2.toString() + "-" + lasyDay];
                }
            },
            handleMonth2(val) {
                if (val.length > 0) {
                    this.date2 = []
                    this.checkDisable4 = true;
                    var lasyDay = "";
                    lasyDay = this.getLastDay(this.yearValue2, val.toString());
                    this.date2 = [this.yearValue2 + "-" + val.toString() + "-01", this.yearValue2 + "-" + val.toString() + "-" + lasyDay];
                } else {
                    this.checkDisable4 = false;
                }
            },
            handleHoliday2(val) {
                if (val.length > 0) {
                    this.checkDisable3 = true;
                    this.holidayList.forEach((item) => {
                        if (item.name == val.toString()) {
                            this.date2 = [item.start_date, item.end_date];
                        }
                    });
                } else {
                    this.checkDisable3 = false;
                }
            },


            //某年某月最后一天
            getLastDay(year, month) {
                var nyear = year;
                var nmonth = month++;
                if (month > 12) {
                    nmonth -= 12;
                    nyear++;
                }
                var n_date = new Date(nyear, nmonth, 1);
                return new Date(n_date.getTime() - 1000 * 60 * 60 * 24).getDate();
            },
            // 颜色
            show1(index1, width) {
                let color = "";
                color = this.colorList[index1];
                return `border-left: ${width} solid ${color};`;
            },

            getDate1(val1, val2, val3) {
                //tjReportNightIncome
                tjReportNightTourist({start_date: val1, end_date: val2}).then(res => {
                    const obj = {}
                    const obj2 = {}
                    if (val3) {
                        if (/[\u4e00-\u9fa5]/.test(val3)) {
                            obj.title = val3
                        }
                        if (/\d/.test(val3)) {
                            obj.title = val3 + '月份'
                        }
                    } else {
                        if (val1 == val2) {
                            obj.title = val1
                        } else {
                            obj.title = `${val1}  ${val2}`
                        }
                    }
                    if (res.data.total_num) {
                        obj.num = res.data.total_num
                    }

                    if (res.data[res.data.length - 1]) {
                        obj.num = res.data[res.data.length - 1].num
                    }
                    obj.date = [val1, val2]
                    if (/[\u4e00-\u9fa5]/.test(val3)) {
                        obj.holiday = [val3]
                    }
                    if (/\d/.test(val3)) {
                        obj.month = [val3]
                    }

                    obj2.name = obj.title
                    obj2.type = 'bar'
                    obj2.barMaxWidth = 20
                    obj2.data = []
                    this.xDataList1 = []
                    res.data.forEach(item => {
                        this.xDataList1.push(item.scenic_spot_name)
                        obj2.data.push(item.num)
                    })

                    if (this.editState) {
                        this.plList1.splice(this.chooseIndex, 1, obj.title)
                        this.series1.splice(this.chooseIndex, 1, obj2)

                    } else {
                        this.plList1.push(obj.title)
                        this.series1.push(obj2)
                    }

                }).then(() => {
                    this.getoption1()
                })
            },
            getoption1() {
                this.option1 = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    legend: {
                        show: false,
                        data: this.plList1,
                        top: "1%",
                        left: "center",
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        data: this.xDataList1
                    }],
                    yAxis: [{
                        type: 'value',
                        name: '人次',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }],
                    series: this.series1
                };
            },

            getDate2(val1, val2, val3) {
                tjReportNightIncome({start_date: val1, end_date: val2}).then(res => {
                    const obj = {}
                    const obj2 = {}
                    if (val3) {
                        if (/[\u4e00-\u9fa5]/.test(val3)) {
                            obj.title = val3
                        }
                        if (/\d/.test(val3)) {
                            obj.title = val3 + '月份'
                        }
                    } else {
                        if (val1 == val2) {
                            obj.title = val1
                        } else {
                            obj.title = `${val1}  ${val2}`
                        }
                    }
                    if (res.data.total_num) {
                        obj.num = res.data.total_num
                    }

                    if (res.data[res.data.length - 1]) {
                        obj.num = res.data[res.data.length - 1].income
                    }
                    obj.date = [val1, val2]
                    if (/[\u4e00-\u9fa5]/.test(val3)) {
                        obj.holiday = [val3]
                    }
                    if (/\d/.test(val3)) {
                        obj.month = [val3]
                    }

                    obj2.name = obj.title
                    obj2.type = 'bar'
                    obj2.barMaxWidth = 20
                    obj2.data = []
                    this.xDataList2 = []
                    res.data.forEach(item => {
                        this.xDataList2.push(item.scenic_spot_name)
                        obj2.data.push(item.income)
                    })

                    if (this.editState) {
                        this.plList2.splice(this.chooseIndex, 1, obj.title)
                        this.series2.splice(this.chooseIndex, 1, obj2)

                    } else {
                        this.plList2.push(obj.title)
                        this.series2.push(obj2)
                    }

                }).then(() => {
                    this.getoption2()
                })
            },
            getoption2() {
                this.option2 = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    legend: {
                        show: false,
                        data: this.plList2,
                        top: "1%",
                        left: "center",
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        data: this.xDataList2
                    }],
                    yAxis: [{
                        type: 'value',
                        name: '元',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }],
                    series: this.series2
                };
            },

            getDate3(val1, val2, val3) {
                tjReportNightIncomeType({start_date: val1, end_date: val2}).then(res => {
                    this.zbList = res.data.list
                    const obj = {}
                    const obj2 = {}
                    obj.name = '项目占比'
                    obj.value = res.data.project_income
                    obj2.name = '门票占比'
                    obj2.value = res.data.ticket_income
                    this.tsscData = [obj, obj2]
                }).then(() => {
                    this.getoption3()
                })
            },
            getoption3() {
                this.option3 = {
                    //hover气泡
                    tooltip: {
                        trigger: "item",
                        position: "right",
                        formatter: "{b}<br/> {c}元 {d}%",
                    },
                    //修改图表颜色
                    color: this.colorList,

                    //graphic控制环形中间出现字，其中有两个对象，就是两行字
                    graphic: [
                        {
                            type: "text", //控制内容为文本文字
                            left: "center",
                            top: "95px", //调整距离盒子高处的位置
                            style: {
                                fill: "#1cffff", //控制字体颜色
                                // text: this.tsscData[0].total || '', //控制第一行字显示内容
                                fontSize: 20,
                            },
                        },
                        {
                            type: "text",
                            left: "center",
                            top: "120px",
                            z: 10,
                            style: {
                                text: "合计占比",
                                fontSize: 14,
                                fill: "#FFFFFF",
                            },
                        },
                    ],
                    series: [
                        {
                            name: "合计占比",
                            type: "pie",
                            radius: ["40%", "70%"],
                            center: ["50%", "45%"],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: "center",
                            },
                            labelLine: {
                                show: false,
                            },
                            data: this.tsscData,
                        },
                    ],
                };

            },


            getDate4(val1, val2, val3) {
                tjReportNightIncomePer({start_date: val1, end_date: val2}).then(res => {
                    const obj = {}
                    const obj2 = {}
                    if (val3) {
                        if (/[\u4e00-\u9fa5]/.test(val3)) {
                            obj.title = val3
                        }
                        if (/\d/.test(val3)) {
                            obj.title = val3 + '月份'
                        }
                    } else {
                        if (val1 == val2) {
                            obj.title = val1
                        } else {
                            obj.title = `${val1}  ${val2}`
                        }
                    }
                    if (res.data.total_num) {
                        obj.num = res.data.total_num
                    }

                    if (res.data[res.data.length - 1]) {
                        obj.num = res.data[res.data.length - 1].price
                    }
                    obj.date = [val1, val2]
                    if (/[\u4e00-\u9fa5]/.test(val3)) {
                        obj.holiday = [val3]
                    }
                    if (/\d/.test(val3)) {
                        obj.month = [val3]
                    }

                    obj2.name = obj.title
                    obj2.type = 'bar'
                    obj2.barMaxWidth = 20
                    obj2.data = []
                    this.xDataList4 = []
                    res.data.forEach(item => {
                        this.xDataList4.push(item.scenic_spot_name)
                        obj2.data.push(item.price)
                    })

                    if (this.editState) {
                        this.dbListAll.splice(this.chooseIndex, 1, obj)
                        this.plList4.splice(this.chooseIndex, 1, obj.title)
                        this.series4.splice(this.chooseIndex, 1, obj2)

                    } else {
                        this.dbListAll.push(obj)
                        this.plList4.push(obj.title)
                        this.series4.push(obj2)
                    }

                }).then(() => {
                    this.getoption4()
                })
            },
            getoption4() {
                this.option4 = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    legend: {
                        show: false,
                        data: this.plList4,
                        top: "1%",
                        left: "center",
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        data: this.xDataList4
                    }],
                    yAxis: [{
                        type: 'value',
                        name: '元',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }],
                    series: this.series4
                };
            },
        },
        /**加载完组件时执行(加载完成之后执行)*/
        mounted() {
        },
    }
</script>

<style lang="scss" scoped>
    .night-container {
        .tip-box {
            cursor: pointer;
            margin-bottom: 23px;

            .every-tip {
                width: 176px;
                height: 41px;
                background: url("../assets/sale/tipSaleBg.png") no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                font-weight: 400;
                text-align: center;
                color: #9bb0cc;
                line-height: 41px;
                margin-right: 32px;
            }

            .every-tip-actived {
                width: 176px;
                height: 41px;
                background: url("../assets/sale/tipSaleActived.png") no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                color: #ffbb00;
                line-height: 41px;
                margin-right: 32px;
            }

            .all-db-box {
                /*width: 680px;*/
                height: 44px;

                .db-box {
                    width: 96px;
                    background: url("../assets/daymanage/4-title-icon2@2x.png") no-repeat;
                    background-size: 100% 100%;
                    height: 32px;
                    /*opacity: 0.9;*/
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    color: #022560;
                    line-height: 32px;
                    cursor: pointer;
                }

                .all-db {
                    /*width: 560px;*/
                    margin-right: 30px;

                    .every-db {
                        position: relative;
                        cursor: pointer;
                        height: 44px;
                        padding: 5px 10px 5px 20px;
                        opacity: 0.6;
                        background: #1e427c;
                        border: 1px solid #0372a6;

                        .pp1 {
                            opacity: 0.7;
                            font-size: 14px;
                            font-weight: normal;
                            text-align: left;
                            color: #ffffff;
                        }

                        .pp2 {
                            font-size: 16px;
                            font-weight: 400;
                            text-align: left;
                            color: #1cffff;
                        }
                    }


                    .every-db:first-child::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #2775C8;
                    }

                    .every-db:nth-child(2)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #189E5D;
                    }

                    .every-db:nth-child(3)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #D27F05;
                    }

                    .every-db:nth-child(4)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #BC5A9D;
                    }

                    .every-db:nth-child(5)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #8C5DD0;
                    }


                }
            }


        }

        .all-moudle {
            .moudle-box {
                width: 908px;
                height: 393px;
                /*opacity: 0.9;*/
                border: 1px solid rgba(4, 70, 101, 0.81);
                background: url("../assets/daymanage/10-2-bg@2x.png") no-repeat;
                background-size: 100% 100%;
                margin-bottom: 24px;
                padding: 26px 24px;

                .renjun-img {
                    position: relative;

                    .wenHao {
                        position: absolute;
                        top: 15px;
                        left: 185px;
                        width: 24px;
                        height: 24px;
                        img{
                            width: 100%;
                            height: 100%;
                        }

                    }
                }

                .search-box {
                    img {
                        width: 288px;
                        height: 44px;
                    }


                    .db-box {
                        width: 96px;
                        background: url("../assets/daymanage/4-title-icon2@2x.png") no-repeat;
                        background-size: 100% 100%;
                        height: 32px;
                        /*opacity: 0.9;*/
                        font-size: 14px;
                        font-weight: 400;
                        text-align: center;
                        color: #022560;
                        line-height: 32px;
                        cursor: pointer;
                    }
                }

                .all-db {
                    margin: 18px 0 0;

                    .every-db {
                        position: relative;
                        cursor: pointer;
                        height: 70px;
                        padding: 5px 0 5px 20px;
                        opacity: 0.6;
                        background: #1e427c;
                        border: 1px solid #0372a6;

                        .pp1 {
                            opacity: 0.7;
                            font-size: 14px;
                            font-weight: normal;
                            text-align: left;
                            color: #ffffff;
                        }

                        .pp2 {
                            font-size: 16px;
                            font-weight: 400;
                            text-align: left;
                            color: #1cffff;
                        }
                    }

                    .every-db:first-child::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #2775C8;
                    }

                    .every-db:nth-child(2)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #189E5D;
                    }

                    .every-db:nth-child(3)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #D27F05;
                    }

                    .every-db:nth-child(4)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #BC5A9D;
                    }

                    .every-db:nth-child(5)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #8C5DD0;
                    }

                    .every-db:nth-child(6)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #CA5538;
                    }

                    .every-db:nth-child(7)::before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        top: 9px;
                        left: 6px;
                        background: #2A80DA;
                    }


                }
            }

            .part3 {
                .info-box {
                    .left-box {
                    }

                    .right-box {
                        .table-head {
                            width: 510px;
                            height: 40px;
                            background: #092859;
                            border: 0.5px solid #3e85b7;
                            font-size: 12px;
                            font-weight: 400;
                            text-align: center;
                            color: #ffffff;

                            .head1 {
                                padding-left: 24px;
                                width: 170px;
                            }

                            .head2 {
                                width: 170px;
                            }

                            .head3 {
                                width: 170px;
                            }

                            .head4 {
                                width: 170px;
                            }

                        }

                        .imp-info {
                            .table-info:nth-child(odd) {
                                width: 510px;
                                height: 40px;
                                background: rgba(5, 70, 145, 0.1);
                            }

                            .table-info:nth-child(even) {
                                width: 510px;
                                height: 40px;
                                background: rgba(5, 70, 145, 0.2);
                            }

                            .table-info {
                                font-size: 14px;
                                font-weight: normal;
                                text-align: center;
                                color: #ffffff;

                                .head1 {
                                    padding-left: 24px;
                                    width: 170px;
                                    font-size: 14px;
                                    text-align: left;
                                    color: #3ce6f0;
                                }

                                .head2 {
                                    width: 170px;
                                }

                                .head4 {
                                    width: 170px;
                                }

                            }
                        }
                    }
                }
            }


        }
    }


    .tc {
        text-align: center;
    }

    .mt15 {
        margin-top: 15px;
    }

    .mr15 {
        margin-right: 15px;
    }

    .flex {
        display: flex;
    }

    .ac {
        align-items: center;
    }

    .jb {
        justify-content: space-between;
    }

    .fw {
        flex-wrap: wrap;
    }

    .bbox {
        box-sizing: border-box;
    }

    .cr {
        cursor: pointer;
    }

    .kj-box {
        background: url('../assets/kuaijie-box.png') no-repeat;
        background-size: 100% 100%;
        width: 96px;
        height: 33px;
        margin-left: 12px;
        cursor: pointer;

        p {
            text-align: center;
            line-height: 33px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
        }
    }


    .kjxz {
        text-align: center;
        font-size: 16px;
        margin: 10px 0;
        color: #999999;
    }

</style>
<style lang="scss">
    .right-select .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .right-select .el-input__icon {
        line-height: 28px !important;
    }

    .right-select2-1 {
        width: 195px;
    }

    .right-select2 .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .right-select2 .el-input__icon {
        line-height: 28px !important;
    }

    .all-date-box .el-input__inner,
    .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        /*background: #042c60 !important;*/
        border: none !important;
        /*color: #37ecff !important;*/
    }

    .all-date-box .el-input__icon {
        line-height: 28px !important;
    }

    .all-date-box .el-range__icon,
    .el-range__close-icon {
        line-height: 20px !important;
    }

    .all-date-box .el-range-separator {
        color: #37ecff !important;
    }

    .chooseYearBox .el-input__inner,
    .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
    }

    .chooseYearBox .el-input__icon {
        line-height: 28px !important;
    }

    .chooseYearBox .el-range__icon,
    .el-range__close-icon {
        line-height: 20px !important;
    }

    .is-selected {
        color: #1989fa;
    }

    .calendar-info {
        font-size: 12px;
    }

    .el-checkbox {
        margin: 0 5px 5px 0;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0;
    }

    .management-box .el-dialog__body {
        padding: 10px 20px !important;
    }

    .part3 .all-date-box .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .part3 .all-date-box .el-input__inner {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }

    .part3 .el-range-input {
        height: 28px !important;
        line-height: 28px !important;
        background: #042c60 !important;
        border: none !important;
        color: #37ecff !important;
    }


</style>

